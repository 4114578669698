import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import Konva from 'konva';
import { PageContext } from '../resolver/PageAnnotationResolver';
import { LayoutService } from '../services/layout.service';
import { KcBackendService } from './kc-backend.service';
import { Pencil } from './konva-paintbox/konva-paintbox.component';
import { ShapeService } from './shape.service';
import Line = Konva.Line;
export const KONVA_VIEW_TYPE_PREVIEW = "KONVA_VIEW_TYPE_PREVIEW";
export const KONVA_VIEW_TYPE_DRAW = "KONVA_VIEW_TYPE_DRAW";
const KONVA_VIEW_TYPES_ARR = [KONVA_VIEW_TYPE_PREVIEW, KONVA_VIEW_TYPE_DRAW] as const;
export type KONVA_VIEW_TYPES = typeof KONVA_VIEW_TYPES_ARR[number];

@Component({
  selector: 'app-konva-canvas',
  templateUrl: './konva-canvas.component.html',
  styleUrls: ['./konva-canvas.component.css'],
})
export class KonvaCanvasComponent implements OnInit, AfterViewInit {
  /**
   *
   *  DIESER BLOCK KÜMMERT SICH DARUM OB DER KONVA ODER DAS BILD ANGEZEIGT WIRD
   */
  private _viewType: KONVA_VIEW_TYPES = KONVA_VIEW_TYPE_PREVIEW;
  public getViewType(): KONVA_VIEW_TYPES {
    return this._viewType;
  }
  public setViewType(newViewType: KONVA_VIEW_TYPES) {
    // WENN DER VIEW TYPE DER SELBE IST, DANN MACH NICHTS
    if (newViewType == this._viewType) return;
    this._viewType = newViewType;
    let stageWith = document.getElementById("allPagesWrapper").offsetWidth;

    if (newViewType == KONVA_VIEW_TYPE_DRAW) {
      const ratio = this.originalW / this.originalH;
      this.scale = stageWith / this.originalW;
      const stageHeight = stageWith / ratio;
      this.stage.height(stageHeight);
      this.stage.width(stageWith);
      this.stage.scale({ x: this.scale, y: this.scale });
      this.stage.draw();
      // this.updateMySnapshots();

    }
    // WENN DER MODE ZUM VIEW PREVIW MODUS WIRD
    if (newViewType == KONVA_VIEW_TYPE_PREVIEW) {
      // this.updateMySnapshots();

    }


  }



  //  private _hilight: boolean = false;
  private _stageWith: number = 0;
  private _stageHeight: number = 0;








  @Input() public editable: boolean = false;
  @Input() public pagesCount = 0;
  @Input() localeJson: object;
  private snapshotIsUpdating = false;
  @Input() public pageContext: PageContext;
  @Input() public myUser: PRM.Observer | PRM.Participant;
  @Input() public set konvaJson(o: any) {
    this.localeJson = o;
    this.konvalength = "" + JSON.stringify(o).length
  }

  @Input() public set bgImageWatermarkOnly(v: { changingThisBreaksApplicationSecurity: string }) {
    return;
    if (v?.changingThisBreaksApplicationSecurity == null) return;
    this.imageObjWatermarkOnly.src = v.changingThisBreaksApplicationSecurity;
    this.localeBgImageWatermarkOnliy = v.changingThisBreaksApplicationSecurity;
    //
    // this.localeBgImage = v.changingThisBreaksApplicationSecurity;
  }

  @Input() public set bgImage(v: { changingThisBreaksApplicationSecurity: string }) {
    if (v?.changingThisBreaksApplicationSecurity == null) return;
    this.imageObj.src = v.changingThisBreaksApplicationSecurity;
    this.localeBgImage = v.changingThisBreaksApplicationSecurity;
  }

  @Output() imageProceeded: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() isDrawingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stageResizeAndRedrawDone: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() screenShotDone: EventEmitter<number> = new EventEmitter<number>();


  @ViewChild('wrapperElement') wrapperElement: ElementRef;
  @ViewChild('stageElement') stageElement: ElementRef;
  //  @ViewChild('snapshotElement') snapshotElement: ElementRef;
  public bitmapdata: string;
  public progressbarProgressing = false;
  private dragStopped = false;
  private tochByStylus: boolean = false;
  public saveWatcherTimeOutId = null;
  public forceSnapshot = false;
  public konvalength = "";
  public pencilMode = true;
  public originalW: number;
  public originalH: number;
  public oldWidth: number = 0;
  public scale = 1;
  public imageObj = new Image();
  public imageObjWatermarkOnly = new Image();

  public konvaRectForBgImage: Konva.Rect;
  public shapes: any = [];
  public redoShapes: any = [];
  private stage: Konva.Stage;
  public layer: Konva.Layer;
  public selectedButton: any = { circle: false, line: false, undo: false, erase: false };
  public eraseToolActive = false;
  public showSnapshot = true;
  public transformers: Konva.Transformer[] = [];
  private localeBgImage: string;
  private localeBgImageWatermarkOnliy: string;
  // tslint:disable-next-line:variable-name
  public _wrapperElementWidth = 100;
  private availabibleW: number;
  private availabibleH: number;
  private currentPencil: Pencil;
  public debug: any = 0;

  constructor(
    private shapeService: ShapeService,
    private backendSerice: KcBackendService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    const css =
      '' +
      '.slider { background-color:' +
      this.layoutService.baseColorButtons.backgroundColor +
      '}' +
      'input:checked + .slider {\n' +
      '  background-color: ' +
      this.layoutService.activeColor.backgroundColor +
      ';\n' +
      '}\n';
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }
  /*
    public shouldShowEditingMode(): boolean {
      if (this.forceSnapshot) {
        return false
      }
      if (this.highlight) {
        return true
      }
      if (this.showSnapshot) {
        return false
      }


      return false;
    }
   */

  invalidateView(selectedPage: number) {
    this.setViewType(KONVA_VIEW_TYPE_PREVIEW);
    if (selectedPage === this.pageContext.page) {
      this.setViewType(KONVA_VIEW_TYPE_DRAW);
    }
  }

  ngAfterViewInit(): void {
    // !!! this.konvaRectForBgImage = new Konva.Rect({
    // !!! steht derzeit in beiden Image loadern weil ich nicht weiß welcher zuerst fertig ist
    /*
        this.imageObjWatermarkOnly.onload = () => {

          this.konvaRectForBgImage = new Konva.Rect({
            width: this.originalW,
            height: this.originalH,
            // disable perfect draw for performance
            // see https://konvajs.org/docs/performance/Disable_Perfect_Draw.html
            perfectDrawEnabled: false,
            fillPatternImage: this.imageObjWatermarkOnly,
          });
        } */
    this.imageObj.onload = () => {
      this.imageProceeded.emit(this.pageContext.page);
      this.originalW = this.imageObj.width;
      this.originalH = this.imageObj.height;
      this.konvaRectForBgImage = new Konva.Rect({
        width: this.originalW,
        height: this.originalH,
        // disable perfect draw for performance
        // see https://konvajs.org/docs/performance/Disable_Perfect_Draw.html
        perfectDrawEnabled: false,
        fillPatternImage: this.imageObj,
      });

      /*    const hyptinuse = Math.sqrt(
           Math.pow(this.originalH, 2) + Math.pow(this.originalW, 2)
         ); */

      //  const a = this.originalH / hyptinuse;
      //const rotation = Math.asin(a) * (180 / Math.PI);
      /*       const watermarkText = new Konva.Text({
              text: this.myUser.firstname + ' ' + this.myUser.lastname,
              fontSize: 280,
              draggable: false,
              width: this.originalW,
              height: this.originalH,
              offsetX: this.originalW / 2,
              offsetY: this.originalH / 2,
              x: this.originalW / 2,
              y: this.originalH / 2,
              fontFamily: 'Calibri',
              fill: 'grey',
              align: 'center',
              verticalAlign: 'middle',
              rotation: rotation * -1,
              opacity: 0.2,
            }); */

      if (this.stage != null) {
        this.stage.destroyChildren();
        this.stage.destroy();
      }
      this.stage = new Konva.Stage({
        container: this.stageElement.nativeElement,
        width: 0,
        height: 0,
      });

      this.layer = new Konva.Layer();
      const bgLayer = new Konva.Layer();
      /* const watermark = new Konva.Layer(); */
      // stop listening for events on this layer --> reduces cycles to compute drawing
      /* watermark.listening(false); */
      bgLayer.listening(false);
      this.stage.add(bgLayer);
      /* this.stage.add(watermark); */
      // set pixelRatio to 1
      // see https://konvajs.org/api/Konva.Canvas.html#pixelRatio
      this.layer.getCanvas().pixelRatio = 1;
      this.stage.add(this.layer);
      bgLayer.add(this.konvaRectForBgImage);
      bgLayer.batchDraw();
      this.addLineListeners();
      this.setSelection('line');
      this.addShape('line');
      this.eraseToolActive = false;
      this.createLinesFromSortedJson();
      bgLayer.batchDraw();
      //      watermark.add(watermarkText);
      /* watermark.batchDraw(); */
      this.imageObj.onload = null;


    };

    if (this.localeBgImage) {
      this.imageObj.src = this.localeBgImage;
    }
  }

  /**
   * Creates a snapshot of the current state of the canvas and updates the thumbnail
   * @return {*}
   * @memberof KonvaCanvasComponent
   */
  public updateMySnapshots() {

    if (this.snapshotIsUpdating) return;
    this.snapshotIsUpdating = true;
    //this.screenShotDone.emit(this.pageContext.page);
    this.stage.toBlob({
      pixelRatio: 0.8,
      callback: (blob) => {
        const url = URL.createObjectURL(blob);
        this.imageObj.onload = () => {
          const thumbHtmlId = this.pageContext.docid + '_' + this.pageContext.page;
          document.getElementById(thumbHtmlId).setAttribute('src', url);
          this.screenShotDone.emit(this.pageContext.page);
          setTimeout(() => {
            URL.revokeObjectURL(url); // Blob-URL nach dem Laden freigeben
            this.snapshotIsUpdating = false;

          }, 10);
        };
        this.imageObj.src = url;
      }
    });
  }



  /**
   * Returns the current state of the canvas as JSON
   *
   * @readonly
   * @memberof KonvaCanvasComponent
   */
  public get konvaJson() {
    return this.localeJson;
  }

  /**
   * Check if is ipad based on "maxTouchPoints" property
   *
   * @return {*}
   * @memberof KonvaCanvasComponent
   */
  isIpad() {
    return navigator.maxTouchPoints == 5;
  }

  public getContentAreaHeight() {
    return window.innerHeight - this.layoutService.headerHeightInt;
  }

  public getContentAreaWidth() {
    return window.innerWidth;
  }


  @HostListener('window:touchstart', ['$event'])
  handleTouchStart(event: TouchEvent): void {
    if (event.touches.length >= 2) {
      this.showSnapshot = true;
    }
  }


  @HostListener('window:touchend', ['$event'])
  handleTouchEnd(event: TouchEvent): void {
    this.showSnapshot = true;
  }


  @HostListener('window:scroll')
  onScroll() {
    // Todo: need to be implemented
  }

  elementInViewport(el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;
    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  }

  zoomIsForced() {
    if (!this.editable) {
      return true;
    }
  }

  public getContentAreaOrientation(): 'landscape' | 'portrait' {
    if (this.getContentAreaWidth() > this.getContentAreaHeight()) {
      return 'landscape';
    }
    return 'portrait';
  }

  createLinesFromSortedJson(): void {
    if (this.konvaJson?.children) {
      this.konvaJson.children.forEach((line) => {
        if (line.attrs.points) {
          line.attrs.points = line.attrs.points.filter((p) => {
            return p != null;
          });
          const newLine = new Konva.Line(line);
          this.shapes.push(newLine);
          //          this.layer.scale({ x: 0.8, y: 0.8 })

          this.layer.add(newLine);
          this.layer.draw();
        }
      });
    }

  }

  clearSelection(): void {
    Object.keys(this.selectedButton).forEach((key) => {
      this.selectedButton[key] = false;
    });
  }

  setSelection(type: string): void {
    this.selectedButton[type] = true;
  }

  addShape(type: string): void {
    this.clearSelection();
    this.setSelection(type);
    this.addLine();
  }

  isStylus(e): boolean {
    return !!this.getStylusTouch(e);
  }

  addLine() {
    this.selectedButton.line = true;
  }

  // TOUCH HELPER
  getStylusTouch(e: any): Touch {
    if (!e) { return undefined; }
    if (!e.evt) { return undefined; }
    if (!e.evt.touches) { return undefined; }
    const touchCount = e.evt.touches.length;
    let found: Touch;
    // tslint:disable-next-line:variable-name
    for (let _i = 0; _i < touchCount; _i++) {
      if (e.evt.touches[_i].touchType === 'stylus') {
        found = e.evt.touches[_i];
      }
    }
    return found;
  }

  fingerCountFromEvent(e) {
    return e?.evt?.touches?.length;
  }

  addLineListeners(): void {
    this.redoShapes = [];
    const component = this;
    let lastLine;
    let isPaint;
    this.stage.on('mousedown touchstart', (e: any) => {
      this.currentPageChange.emit(this.pageContext.page);
      //wenn ich auf dem ipad bin
      if (this.isIpad()) {
        //wenn wenn ich einen Stylus benutze
        if (this.isStylus(e)) {
          this.tochByStylus = true;
          // STYLUS FOUND
        } else {
          this.tochByStylus = false;
          ///AUF DEM IPAD OHNE STYLUS ZOOME ICH IMMER MIT 2 FINGER
          if (this.fingerCountFromEvent(e) != 2) {
            return;
          }
        }

      }

      if (!this.pencilMode) {
        return;
      }
      this.currentPageChange.emit(this.pageContext.page);
      if (!component.selectedButton.line && !component.eraseToolActive) {
        return;
      }
      isPaint = true;
      const pos = component.stage.getPointerPosition();
      let newPoints = [pos.x / this.scale, pos.y / this.scale];
      const mode = component.eraseToolActive ? 'erase' : 'brush';
      if ('' + newPoints[0] === 'undefined') {
        newPoints = [];
      }

      lastLine = component.shapeService.line(newPoints, mode);
      component.shapes.push(lastLine);
      component.layer.add(lastLine);
      this.redoShapes = [];
    });

    this.stage.on('mouseup touchend', (e) => {
      isPaint = false;
      // TODO: NUR SPEICHERN WENN AUCH WIRKLICH GEMALT WURDE
      this.save();
      // NUR DAS BILD TAUSCHEN WENN VORHER DER STIFT __NICHT_ DRAN WAR
      // DAS BILD __NIE_ wärend des schreibens updaten
      /* if (!this.tochByStylus) {
        this.updatePageThumbnail();
      } */
    });

    this.stage.on('mousemove touchmove', (e: any) => {
      if (this.fingerCountFromEvent(e) == 2) { return; }
      if (!isPaint) { return; }
      e.evt.preventDefault();
      const pos = component.stage.getPointerPosition();
      const newPoints = lastLine.points().concat([pos.x / this.scale, pos.y / this.scale]);
      while (newPoints.length > 1 && '' + newPoints[0] === 'undefined') {
        newPoints.shift();
      }
      this.isDrawingEvent.emit(true);
      lastLine.points(newPoints);
      component.layer.batchDraw();
    });
  }

  redo(): void {
    const storedShape = this.redoShapes.pop();
    const component = this;
    if (storedShape) {
      component.shapes.push(storedShape);
      component.layer.add(storedShape);
      component.layer.draw();
    }
    //    this.updatePageThumbnail();

    this.save();

  }

  undo(): void {
    const removedShape = this.shapes.pop();
    this.redoShapes.push(removedShape);
    this.transformers.forEach((t) => {
      t.detach();
    });
    if (removedShape) {
      removedShape.remove();
    }
    this.layer.draw();

    this.save();
  }

  setPencil(p: Pencil): void {
    this.eraseToolActive = p.iseraser;
    this.currentPencil = p;
    this.shapeService.setPencil(p);
  }



  async setStageWidth(stageWith: number) {
    if (stageWith == this.oldWidth) return;
    this.scale = stageWith / this.originalW;
    const ratio = this.originalW / this.originalH;
    console.log("SETTI METTI");
    console.log("ratio", this.originalH);
    const stageHeight = stageWith / ratio;

    this._stageHeight = stageHeight;
    this._stageWith = stageWith;

  }

  /* updatePageThumbnail() {
    return;
    //!!! TODO: GGF DAS THUMB NUR UPDATEN WENN SICH DIE ANZAHL LIENEN GEÄNDERT HAT
    //!!! this.stage.children[2] <-- da sind die linien (oder so)
  }
  */
  save(): void {
    this.backendSerice.save(this.stage, this.pageContext);
    this.updateMySnapshots();
  }


  public htmlHasLoadedImg() {
    const attr = document.getElementById(this.pageContext.docid + "_" + this.pageContext.page).getAttribute("src");
    if (!attr) {
      const src = document.getElementById("konva_large_image_" + this.pageContext.page).getAttribute("src")
      document.getElementById(this.pageContext.docid + "_" + this.pageContext.page).setAttribute("src", src)
      console.log("Fixed broken image")
    }
  }
}
