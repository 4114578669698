<h4
  [appLayout]="layoutService.baseColorButtons"
  (click)="this.epanded = !this.epanded"
  [appLayoutColorFlip]="true"
>
  <i
    class="ri-arrow-up-s-line"
    *ngIf="epanded"
  ></i>
  <i
    class="ri-arrow-down-s-line"
    *ngIf="!epanded"
  ></i>
  {{event.timeblock.name}}
</h4>

<div
  class="mt-3 "
  *ngIf="epanded"
>
  <div
    class="row mb-5"
    *ngFor="let document of event.participantSummaryDocuments"
  >
    <div class="col-6">
      <div class="row">
        <div class="col-6 ">
          <a
            class="d-block  text-center"
            [routerLink]="getRouterLinkToSingleDocView(event, document)"
          >


            <img
              alt="Thumbnail summary-timeblock"
              class="img-fluid img-thumbnail"
              style="background-color: #fff"
              [src]="'/api/pdf/getpage/' + document._id + '/0' | secure | async"
            >
            <span style="color:#232E55">
              {{document.metadata.docname}} ({{document.metadata.pagescount}})
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div
        i18n
        class="div alert alert-notice-header"
        [style.backgroundColor]="layoutService.gray50.backgroundColor"
      >
        Notizen
      </div>
      <div *ngIf="mycomments &&  mycomments[document?._id] != undefined">
        <ng-container *ngFor="let idx of counter(document.metadata.pagescount); index as p">
          <div *ngIf="mycomments[document._id][p]">
            <div class="d-flex">

              <i
                class="ri-message-2-fill mr-2"
                [appLayout]="layoutService.activeColor"
                style="transform: scaleX(-1)"
                [appLayoutColorFlip]="true"
              ></i>
              <b
                i18n
                style="font-weight: bold"
              >Seite</b> {{p + 1}}:
            </div>

            <div
              *ngIf="mycomments[document._id][p]"
              [innerHTML]="mycomments[document._id][p]"
              style="margin-left: 24px "
              class="pl-2"
              [appLayout]="layoutService.textColor50"
            ></div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row text-left ">
        <div class="col-12">
          <div
            class="card mb-2 "
            *ngIf="event.notes.length > 0"
          >
            <div
              i18n
              class="card-header"
              [appLayout]="layoutService.baseColorButtons"
            >
              Generelle Notizen
            </div>

            <div class="card-body">
              <div
                *ngFor="let notes of event.notes"
                [innerHTML]="notes"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-left pt-2">
        <div
          class="col-6"
          *ngIf="event.strengths.length > 0"
        >
          <div class="card mb-2">
            <div
              i18n
              class="card-header"
              [appLayout]="layoutService.baseColorButtons"
            >
              Stärken
            </div>
            <div class="card-body">
              <div
                *ngFor="let strength of event.strengths"
                [innerHTML]="strength"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="col-6"
          *ngIf="event.developmentAreas.length > 0"
        >
          <div class="card mb-2">
            <div
              i18n
              class="card-header"
              [appLayout]="layoutService.baseColorButtons"
            >
              Entwicklungsfelder
            </div>
            <div class="card-body">
              <div
                *ngFor="let developmentArea of event.developmentAreas"
                [innerHTML]="developmentArea"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>