import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { PackService } from './PackService';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  private vimeoIdToThumbnail: Map<string, string> = new Map<string, string>();
  constructor(private http: HttpClient, private service: PackService) { }

  public async setVimeoThumbnail(vimeoId: string) {
    const url = 'https://vimeo.com/api/oembed.json?url=' + encodeURIComponent('https://player.vimeo.com/video/' + vimeoId);
    const result = await firstValueFrom(this.http.get<any>(url));

    if (result && result.thumbnail_url) {
      const thumbnailUrl = decodeURIComponent(result.thumbnail_url);
      this.vimeoIdToThumbnail.set(vimeoId, thumbnailUrl);
    } else {
      this.vimeoIdToThumbnail.set(vimeoId, "error");
    }
  }

  public getVimenoThumbnail(vimeoId: string) {
    if (!this.vimeoIdToThumbnail.has(vimeoId)) {
      this.setVimeoThumbnail(vimeoId);
    }
    if (this.vimeoIdToThumbnail.get(vimeoId) == "error") {
      return null;
    }
    return this.vimeoIdToThumbnail.get(vimeoId);
  }

  public async getVimeoInfo(videoId: string): Promise<any> {
    const pack = await this.service.getCurrentPackage();

    for (const eventday of pack.eventdays) {
      for (const ev of eventday.events) {
        for (const evdoc of ev.documents) {
          if ((evdoc as any)._id === videoId) return { isVimeoVideo: (evdoc as any).metadata.type === 'vimeo', id: (evdoc as any).metadata.vimeovideo }
        }
        if (ev.timeblock.documents) {
          for (const evdoc of ev.timeblock.documents) {
            if (evdoc._id === videoId) return { isVimeoVideo: evdoc.metadata.type === 'vimeo', id: evdoc.metadata.vimeovideo }
          }
        }
      }
    }

    for (const doc of pack.introDocs) {
      if (doc._id === videoId) return { isVimeoVideo: (doc as any).metadata.type === 'vimeo', id: (doc as any).metadata.vimeovideo }
    }
    return null;
  }
}
