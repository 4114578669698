import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEnGb from '@angular/common/locales/en-GB';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';
import { NgChartsModule } from 'ng2-charts';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CountdownModule } from 'ngx-countdown';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { UploadxModule } from 'ngx-uploadx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginNoPrivacyPolicyModule } from './login-no-privacy-policy/login-no-privacy-policy.module';
import { DocumentContentRendererComponent } from './observer-view/document-content-renderer/document-content-renderer.component';
import { ObserverViewModule } from './observer-view/observer-view.module';
import { PartiViewModule } from './parti-view/parti-view.module';
import { ParticipantsummaryComponent } from './participantsummary/participantsummary.component';
import { SummaryTimeblockComponent } from './participantsummary/summary-timeblock/summary-timeblock.component';
import { PipesModule } from './pipes/pipes.module';
import { SocketService } from './shared/SocketService';
import { ConfirmDeleteVideoComponent } from './shared/confirm-delete-video/confirm-delete-video.component';
import { DocumentContentRendererSingleDocoldComponent } from './shared/document-content-renderer-single-docold/document-content-renderer-single-docold.component';
import { DocumentContentRendererSingleVideoComponent } from './shared/document-content-renderer-single-video/document-content-renderer-single-video.component';
import { DocumentListComponent } from './shared/document-list/document-list.component';
import { HttpConcurrencyInterceptor } from './shared/http-concurrency.service';
import { HttpErrorHandlingInterceptor } from './shared/http-error-handling-interceptor.service';
import { IntroductionListComponent } from './shared/introduction-list/introduction-list.component';
import { IntroductionRendererSingleDocComponent } from './shared/introduction-renderer-single-doc/introduction-renderer-single-doc.component';
import { KonvaCanvasComponent } from './shared/konva-canvas/konva-canvas.component';
import { KonvaPaintboxComponent } from './shared/konva-canvas/konva-paintbox/konva-paintbox.component';
import { KonvaToolSelectorComponent } from './shared/konva-tool-selector/konva-tool-selector.component';
import { PreptimeModalComponent } from './shared/preptime-modal/preptime-modal.component';
import { ResultconferenceGuard } from './shared/resultconference.guard';
import { RiButtonComponent } from './shared/ri-button/ri-button.component';
import { SafePipe } from './shared/safe.pipe';
import { PackService } from './shared/services/PackService';
import { PdfAnnotationService } from './shared/services/PdfAnnotationService';
import { LayoutService } from './shared/services/layout.service';
import { VideoRecordingService } from './shared/video-recording.service';
import { WcagComponent } from './shared/wcag/wcag.component';
import { WcagService } from './shared/wcag/wcag.service';

registerLocaleData(localeEnGb, 'en-GB');

@NgModule({
  declarations: [
    KonvaCanvasComponent,
    KonvaPaintboxComponent,
    KonvaToolSelectorComponent,
    AppComponent,
    DocumentContentRendererComponent,
    DocumentContentRendererSingleDocoldComponent,
    ParticipantsummaryComponent,
    DocumentContentRendererSingleVideoComponent,
    IntroductionListComponent,
    IntroductionRendererSingleDocComponent,
    SummaryTimeblockComponent,
    RiButtonComponent,
    DocumentListComponent,
    PreptimeModalComponent,
    ConfirmDeleteVideoComponent,
    SafePipe
  ],
  imports: [
    NgChartsModule,
    QRCodeModule,
    TooltipModule.forRoot(),
    CommonModule,
    CountdownModule,
    UploadxModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule,
    AppRoutingModule,
    FormsModule,
    ObserverViewModule,
    PartiViewModule,
    PipesModule,
    LoginNoPrivacyPolicyModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'color': [] }],
          [{ 'align': [] }],
          ['clean'],
        ]
      }
    })

  ],
  providers: [
    WcagComponent,
    WcagService,
    SocketService,
    PackService,
    LayoutService,
    PdfAnnotationService,
    BsModalService,
    ResultconferenceGuard,
    VideoRecordingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConcurrencyInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    SafePipe
  ]
})
export class AppModule {
  constructor() {
    if (!isDevMode() && localStorage.getItem("debugger") != 'true') {
      document.addEventListener('contextmenu', event => event.preventDefault());
    }
    window["tmpconsole"] = console.log;
    console.log = (...data) => {
      if (isDevMode() || localStorage.getItem("debugger") == 'true') {
        window["tmpconsole"](...data);
      }
    }
  }
}